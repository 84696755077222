<template>
  <div v-if="transaction" id="printMe" class="transactions-print-view">
    <div style="padding: 32px 32px 0px">
      <div :style="transactionNameStyle">
        {{ transaction.transactionName }}
      </div>
    </div>

    <div :style="transactionDetailsStyle">
      <div :style="transactionRowStyle">
        <div :style="transactionLabelStyle">
          {{ $t("DealValue") }}
        </div>
        <div :style="transactionValueStyle">
          {{ transaction.dealValue ? transaction.dealValue : "-" }} €
        </div>
      </div>
      <div :style="transactionRowStyle">
        <div :style="transactionLabelStyle">
          {{ $t("BrokerageFee") }}
        </div>
        <div :style="transactionValueStyle">
          {{ transaction.brokerageFee ? transaction.brokerageFee + " €" : "-" }}
        </div>
      </div>
      <div v-if="transaction.transactionDate" :style="transactionRowStyle">
        <div :style="transactionLabelStyle">
          {{ $t("DateOfTransaction") }}
        </div>
        <div :style="transactionValueStyle">
          {{ transactionDate }}
        </div>
      </div>
      <div v-if="transaction.status" :style="transactionRowStyle">
        <div :style="transactionLabelStyle">
          {{ $t("Status") }}
        </div>
        <div :style="transactionValueStyle">
          {{ status }}
        </div>
      </div>
      <div
        v-if="
          transaction.booking &&
          (transaction.booking.date || transaction.booking.fee)
        "
        :style="transactionRowStyle"
      >
        <div :style="transactionLabelStyle">
          {{ $t("BookingDateAndFee") }}
        </div>
        <div :style="transactionValueStyle">
          {{ bookingDateAndFee }}
        </div>
      </div>
      <div
        v-if="
          transaction.obligationsContract &&
          (transaction.obligationsContract.date ||
            transaction.obligationsContract.fee)
        "
        :style="transactionRowStyle"
      >
        <div :style="transactionLabelStyle">
          {{ $t("ObligationsDateAndFee") }}
        </div>
        <div :style="transactionValueStyle">
          {{ _obligationsDateAndFee }}
        </div>
      </div>
      <div v-if="transaction.premiseDeliveryDate" :style="transactionRowStyle">
        <div :style="transactionLabelStyle">
          {{ $t("PremiseDeliveryDate") }}
        </div>
        <div :style="transactionValueStyle">
          {{ _premiseDeliveryDate }}
        </div>
      </div>
      <div
        v-if="transaction.seller && transaction.seller.length > 0 && (transaction.creator.userId === user._id || user.isAdmin)"
        :style="transactionRowStyle"
      >
        <div :style="transactionLabelStyle">
          {{ $t("Seller") }}
        </div>
        <div style="display: flex; flex-direction: column; gap: 12px">
          <div v-for="seller in transaction.seller" :key="seller._id">
            <div v-if="seller.type === 'private'">
              <div :style="transactionValueBoldStyle">
                {{ seller.name }}
              </div>
              <div :style="transactionValueStyle">
                {{ seller.personalCode }}
              </div>
              <div :style="transactionValueStyle">
                {{ seller.address }}
              </div>
            </div>
            <div v-else-if="seller.type === 'company'">
              <div :style="transactionValueBoldStyle">
                {{ seller.companyName }}
              </div>
              <div :style="transactionValueStyle">
                {{ seller.registryCode }}
              </div>
              <div :style="transactionValueStyle">
                {{ seller.companyAddress }}
              </div>
              <div :style="transactionValueStyle">
                {{ seller.signatory }}
                <div />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="transaction.buyer && transaction.buyer.length > 0 && (transaction.creator.userId === user._id || user.isAdmin)"
        :style="transactionRowStyle"
      >
        <div :style="transactionLabelStyle">
          {{ $t("Buyer") }}
        </div>
        <div style="display: flex; flex-direction: column; gap: 12px">
          <div v-for="buyer in transaction.buyer" :key="buyer._id">
            <div v-if="buyer.type === 'private'">
              <div :style="transactionValueBoldStyle">
                {{ buyer.name }}
              </div>
              <div :style="transactionValueStyle">
                {{ buyer.personalCode }}
              </div>
              <div :style="transactionValueStyle">
                {{ buyer.address }}
              </div>
            </div>
            <div v-else-if="buyer.type === 'company'">
              <div :style="transactionValueBoldStyle">
                {{ buyer.companyName }}
              </div>
              <div :style="transactionValueStyle">
                {{ buyer.registryCode }}
              </div>
              <div :style="transactionValueStyle">
                {{ buyer.companyAddress }}
              </div>
              <div :style="transactionValueStyle">
                {{ buyer.signatory }}
                <div />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "TransactionPrintView",
  props: ["transaction", "print"],
  computed: {
    /*   brokerageFee() {
      if (this.transaction.brokerageFee) {
        return this.transaction.brokerageFee;
      } else if (this.transaction.minimumFee) {
        return this.minimumFee;
      } else {
        return "-"
      }
    }, */
    transactionDate() {
      if (this.transaction.transactionDate) {
        return moment(this.transaction.transactionDate).format("DD.MM.YYYY");
      }
      return "-";
    },
    status() {
      return this.transaction.status === "paid"
        ? this.$t("Paid")
        : this.$t("NotPaid");
    },
    bookingDateAndFee() {
      const transactionDate = this.transaction.transactionDate
        ? moment(this.transaction.transactionDate).format("DD.MM.YYYY")
        : "-";

      const fee = this.transaction.booking.fee
        ? this.transaction.booking.fee
        : "-";

      return `${transactionDate} / ${fee} €`;
    },
    _obligationsDateAndFee() {
      const obligationsDateAndFee = this.transaction.obligationsContract.date
        ? moment(this.transaction.obligationsContract.date).format("DD.MM.YYYY")
        : "-";

      const fee = this.transaction.obligationsContract.fee
        ? this.transaction.obligationsContract.fee
        : "-";

      return `${obligationsDateAndFee} / ${fee} €`;
    },
    _premiseDeliveryDate() {
      return moment(this.transaction.premiseDeliveryDate).format("DD.MM.YYYY");
    },
  },
  data() {
    return {
      transactionNameStyle:
        "font-family: 'Geomanist';\n" +
        "font-style: normal;\n" +
        "font-weight: 500;\n" +
        "font-size: 24px;\n" +
        "line-height: 24px;",
      transactionDetailsStyle:
        "display: flex;\n" +
        "flex-direction: column;\n" +
        "align-items: flex-start;\n" +
        "padding: 32px 72px 112px 32px;",
      transactionRowStyle:
        "display: flex;\n" +
        "flex-direction: row;\n" +
        "align-items: start;\n" +
        "margin-top: 12px;",
      transactionLabelStyle:
        "width: 256px;font-weight: 400;\n" +
        "font-size: 14px;\n" +
        "line-height: 20px;\n" +
        "color: #75787A;",
      transactionValueStyle:
        "font-weight: 400;\n" + "font-size: 14px;\n" + "line-height: 20px;",
      transactionValueBoldStyle:
        "font-weight: 600;\n" + "font-size: 14px;\n" + "line-height: 20px;",
    };
  },
  watch: {
    print() {
      this.$htmlToPaper("printMe");
    },
  },
};
</script>

<style scoped></style>
