<template>
  <div
    @click="closePopover($event)"
    id="statuses-dropdown-overlay"
    class="overlay"
    v-click-outside="closePopOver"
  >
    <div id="statuses-dropdown">
      <div
        v-for="(status, index) in statuses"
        :key="index"
        class="status-label-wrapper"
      >
        <div
          @click="selectValue(status.value)"
          class="status-label"
          :style="'background:' + status.color"
        >
          <p class="content-bold">{{ status.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue-date-pick/dist/vueDatePick.css";
export default {
  name: "StatusDropdown",
  props: ["position", "transaction"],
  components: {},
  data() {
    return {
      statusModalOpen: false,
      chosenStatus: null,
      chosenUntil: null,
    };
  },
  created() {},
  mounted() {
    let topPosition = this.position.top;
    let rightPosition = this.position.right;
    document
      .getElementById("statuses-dropdown")
      .style.setProperty("top", topPosition + "px");
    document
      .getElementById("statuses-dropdown")
      .style.setProperty("right", rightPosition + "px");
  },
  beforeDestroy() {},
  computed: {
    statuses() {
      if (this.transaction.isUnit) {
        return [
          { name: this.$t("Completed"), value: "paid", color: "#b4e7cf" },
          { name: this.$t("Incomplete"), value: "not_paid", color: "#ffb4b6" },
        ];
      }
      return [
        { name: this.$t("Paid"), value: "paid", color: "#b4e7cf" },
        { name: this.$t("NotPaid"), value: "not_paid", color: "#ffb4b6" },
      ];
    },
  },
  methods: {
    selectValue(value) {
      this.$emit("statusChanged", value);
      this.$emit("closed");
    },
    closePopOver() {
      this.$emit("closed");
    },
    closePopover(e) {
      let popoverWrapper = document.getElementById("statuses-dropdown-overlay");
      if (e.target === popoverWrapper) {
        this.$emit("closed");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#statuses-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 140px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  min-width: 116px;
  height: 20px;
  background: #75787a;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 1;
  cursor: pointer;
}
.status-label-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  min-width: 140px;
  height: 44px;
  background: #ffffff;
  border-radius: 8px;
  p {
    color: black;
  }
}
</style>
