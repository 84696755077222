var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.transaction ? _c('div', {
    staticClass: "transactions-print-view",
    attrs: {
      "id": "printMe"
    }
  }, [_c('div', {
    staticStyle: {
      "padding": "32px 32px 0px"
    }
  }, [_c('div', {
    style: _vm.transactionNameStyle
  }, [_vm._v(" " + _vm._s(_vm.transaction.transactionName) + " ")])]), _c('div', {
    style: _vm.transactionDetailsStyle
  }, [_c('div', {
    style: _vm.transactionRowStyle
  }, [_c('div', {
    style: _vm.transactionLabelStyle
  }, [_vm._v(" " + _vm._s(_vm.$t("DealValue")) + " ")]), _c('div', {
    style: _vm.transactionValueStyle
  }, [_vm._v(" " + _vm._s(_vm.transaction.dealValue ? _vm.transaction.dealValue : "-") + " € ")])]), _c('div', {
    style: _vm.transactionRowStyle
  }, [_c('div', {
    style: _vm.transactionLabelStyle
  }, [_vm._v(" " + _vm._s(_vm.$t("BrokerageFee")) + " ")]), _c('div', {
    style: _vm.transactionValueStyle
  }, [_vm._v(" " + _vm._s(_vm.transaction.brokerageFee ? _vm.transaction.brokerageFee + " €" : "-") + " ")])]), _vm.transaction.transactionDate ? _c('div', {
    style: _vm.transactionRowStyle
  }, [_c('div', {
    style: _vm.transactionLabelStyle
  }, [_vm._v(" " + _vm._s(_vm.$t("DateOfTransaction")) + " ")]), _c('div', {
    style: _vm.transactionValueStyle
  }, [_vm._v(" " + _vm._s(_vm.transactionDate) + " ")])]) : _vm._e(), _vm.transaction.status ? _c('div', {
    style: _vm.transactionRowStyle
  }, [_c('div', {
    style: _vm.transactionLabelStyle
  }, [_vm._v(" " + _vm._s(_vm.$t("Status")) + " ")]), _c('div', {
    style: _vm.transactionValueStyle
  }, [_vm._v(" " + _vm._s(_vm.status) + " ")])]) : _vm._e(), _vm.transaction.booking && (_vm.transaction.booking.date || _vm.transaction.booking.fee) ? _c('div', {
    style: _vm.transactionRowStyle
  }, [_c('div', {
    style: _vm.transactionLabelStyle
  }, [_vm._v(" " + _vm._s(_vm.$t("BookingDateAndFee")) + " ")]), _c('div', {
    style: _vm.transactionValueStyle
  }, [_vm._v(" " + _vm._s(_vm.bookingDateAndFee) + " ")])]) : _vm._e(), _vm.transaction.obligationsContract && (_vm.transaction.obligationsContract.date || _vm.transaction.obligationsContract.fee) ? _c('div', {
    style: _vm.transactionRowStyle
  }, [_c('div', {
    style: _vm.transactionLabelStyle
  }, [_vm._v(" " + _vm._s(_vm.$t("ObligationsDateAndFee")) + " ")]), _c('div', {
    style: _vm.transactionValueStyle
  }, [_vm._v(" " + _vm._s(_vm._obligationsDateAndFee) + " ")])]) : _vm._e(), _vm.transaction.premiseDeliveryDate ? _c('div', {
    style: _vm.transactionRowStyle
  }, [_c('div', {
    style: _vm.transactionLabelStyle
  }, [_vm._v(" " + _vm._s(_vm.$t("PremiseDeliveryDate")) + " ")]), _c('div', {
    style: _vm.transactionValueStyle
  }, [_vm._v(" " + _vm._s(_vm._premiseDeliveryDate) + " ")])]) : _vm._e(), _vm.transaction.seller && _vm.transaction.seller.length > 0 && (_vm.transaction.creator.userId === _vm.user._id || _vm.user.isAdmin) ? _c('div', {
    style: _vm.transactionRowStyle
  }, [_c('div', {
    style: _vm.transactionLabelStyle
  }, [_vm._v(" " + _vm._s(_vm.$t("Seller")) + " ")]), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "12px"
    }
  }, _vm._l(_vm.transaction.seller, function (seller) {
    return _c('div', {
      key: seller._id
    }, [seller.type === 'private' ? _c('div', [_c('div', {
      style: _vm.transactionValueBoldStyle
    }, [_vm._v(" " + _vm._s(seller.name) + " ")]), _c('div', {
      style: _vm.transactionValueStyle
    }, [_vm._v(" " + _vm._s(seller.personalCode) + " ")]), _c('div', {
      style: _vm.transactionValueStyle
    }, [_vm._v(" " + _vm._s(seller.address) + " ")])]) : seller.type === 'company' ? _c('div', [_c('div', {
      style: _vm.transactionValueBoldStyle
    }, [_vm._v(" " + _vm._s(seller.companyName) + " ")]), _c('div', {
      style: _vm.transactionValueStyle
    }, [_vm._v(" " + _vm._s(seller.registryCode) + " ")]), _c('div', {
      style: _vm.transactionValueStyle
    }, [_vm._v(" " + _vm._s(seller.companyAddress) + " ")]), _c('div', {
      style: _vm.transactionValueStyle
    }, [_vm._v(" " + _vm._s(seller.signatory) + " "), _c('div')])]) : _vm._e()]);
  }), 0)]) : _vm._e(), _vm.transaction.buyer && _vm.transaction.buyer.length > 0 && (_vm.transaction.creator.userId === _vm.user._id || _vm.user.isAdmin) ? _c('div', {
    style: _vm.transactionRowStyle
  }, [_c('div', {
    style: _vm.transactionLabelStyle
  }, [_vm._v(" " + _vm._s(_vm.$t("Buyer")) + " ")]), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "12px"
    }
  }, _vm._l(_vm.transaction.buyer, function (buyer) {
    return _c('div', {
      key: buyer._id
    }, [buyer.type === 'private' ? _c('div', [_c('div', {
      style: _vm.transactionValueBoldStyle
    }, [_vm._v(" " + _vm._s(buyer.name) + " ")]), _c('div', {
      style: _vm.transactionValueStyle
    }, [_vm._v(" " + _vm._s(buyer.personalCode) + " ")]), _c('div', {
      style: _vm.transactionValueStyle
    }, [_vm._v(" " + _vm._s(buyer.address) + " ")])]) : buyer.type === 'company' ? _c('div', [_c('div', {
      style: _vm.transactionValueBoldStyle
    }, [_vm._v(" " + _vm._s(buyer.companyName) + " ")]), _c('div', {
      style: _vm.transactionValueStyle
    }, [_vm._v(" " + _vm._s(buyer.registryCode) + " ")]), _c('div', {
      style: _vm.transactionValueStyle
    }, [_vm._v(" " + _vm._s(buyer.companyAddress) + " ")]), _c('div', {
      style: _vm.transactionValueStyle
    }, [_vm._v(" " + _vm._s(buyer.signatory) + " "), _c('div')])]) : _vm._e()]);
  }), 0)]) : _vm._e()])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }